import React, { useState, useContext, useCallback } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "../../universal/Account";
import { AttributeContext } from "../../universal/Attributes";
import { Button, Form, FloatingLabel, Stack, Alert } from "react-bootstrap";

// eslint-disable-next-line
const ChangePrefCOGID = (props) => {
  const [newPreferredCOGID, setNewPreferredCOGID] = useState(props.cogid);
  const [COGIDValidated, setCOGIDValidated] = useState(false);

  // Wrong Password Alerts
  const [PasswordAlert, setPasswordAlert] = useState(false);
  const [PasswordAlertMsg, setPasswordAlertMsg] = useState("");
  const [PasswordAlertMsgSubtitle, setPasswordAlertMsgSubtitle] = useState("");
  const [AlertType, setAlertType] = useState("danger");

  const Attributes = useContext(AttributeContext);
  const { setCogid } = Attributes;

  const { pullAttributes } = useContext(AccountContext);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    pullAttributes().then(({ user }) => {
      
          const attributes = [
            new CognitoUserAttribute({
              Name: "custom:prefferedcogid",
              Value: newPreferredCOGID,
            }),
          ];

          user.updateAttributes(attributes, (err, results) => {
            if (err) {
              //console.error(err);
              setPasswordAlert(true);
              setAlertType("danger");
              setPasswordAlertMsg("Error Changing COG ID");
              setPasswordAlertMsgSubtitle(err.message);
            } else {
              //console.log(results);
              setPasswordAlert(true);
              setAlertType("success");
              setPasswordAlertMsg(
                "Preferred COG ID Changed to " + newPreferredCOGID + "!"
              );
              setPasswordAlertMsgSubtitle(
                results.substring(0, 1) + results.substring(1).toLowerCase()
              );
              setCogid(newPreferredCOGID);
            }
          });

    });
        
  }, [newPreferredCOGID, pullAttributes, setCogid]);

  // This uses RegEx to validate the COG ID format. it then sets the state of the COG ID value and the COG ID validation.
  const COGIDValidation = (cogid) => {
    setNewPreferredCOGID(cogid);
    var re = {
      length: /(?=^[0-9]{6}$)/,
    };
    setCOGIDValidated(re.length.test(cogid) && cogid !== props.cogid);
  };

  return (
    <div>
      <Form
        className="COGID-change-form"
        noValidate
        onSubmit={onSubmit}
        validated={COGIDValidated}
      >
        {/* Input Field Stack */}
        <Stack gap={3}>
          <Form.Group className="mb-3" controlId="formBasicCOGIDChange">
            <FloatingLabel
              controlId="floatingInputNewCOGID"
              label="COG ID"
              className="mb-2"
            >
              <Form.Control
                type="number"
                onChange={(event) => COGIDValidation(event.target.value)}
                style={{ height: "50px" }}
                autoComplete="off"
              />
            </FloatingLabel>
            <Form.Text id="COGIDHelpBlock" muted>
              The COG ID entered must be a six digit number. Ex: ######
            </Form.Text>
          </Form.Group>

        </Stack>

        {/* Button Stack */}
        <Stack gap={3}>
          <Form.Group>
            <Button
              variant={!COGIDValidated ? "warning" : "success"}
              type="submit"
              disabled={!COGIDValidated}
            >
              Change Preferred COGID
            </Button>
          </Form.Group>
          <Alert
            className="PasswordAlert"
            show={PasswordAlert}
            onClose={() => setPasswordAlert(false)}
            variant={AlertType}
            dismissible
          >
            <Alert.Heading>{PasswordAlertMsg}</Alert.Heading>
            <p>{PasswordAlertMsgSubtitle}</p>
          </Alert>
        </Stack>
      </Form>
    </div>
  );
};

export default ChangePrefCOGID;
